import axios from 'axios'

// configure axios
const API_HOST = process.env.VUE_APP_API_HOST
axios.defaults.baseURL = `http://${API_HOST}/api/v1`
axios.defaults.headers['Access-Control-Allow-Origin'] = '*'

export default {
  newSecret (formData) {
    return axios.post('/secret', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  readSecret (hash) {
    return axios.get(`/secret/${hash}`)
  }
}
