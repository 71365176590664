<template>
  <div class="text-field">
    <label>{{ label }}:</label>
    <input
      :type="type"
      :value="modelValue"
      @input="update"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      required: true
    },
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  emits: ['update:modelValue'],
  inheritAttrs: false,
  methods: {
    update (e) {
      this.$emit('update:modelValue', e.target.value)
    }
  }
}
</script>

<style>
.text-field {
  margin: 10px;
  width: 100%;
}

.text-field input {
  width: 100%;
  margin: 5px;
  padding: 10px;
  background-color: #000;
  color: #fff;
  letter-spacing: 1px;
}

</style>
